/* Opening & closing buttons */

.m-panel.m-zoompanel.collapsed {
    height: 40px;
}

.m-zoompanel.opened .m-panel-btn.g-cartografia-flecha-derecha,
.m-zoompanel.opened .m-panel-btn.g-cartografia-flecha-izquierda {
    background-color: white !important;
    color: #71a7d3 !important;
}

/* Title and images container */

.m-control.m-container.m-zoompanel {
    min-width: 200px;
}

/* Para Microsoft EDGE*/
@supports (-ms-ime-align:auto) {
    .m-control.m-container.m-zoompanel {
        min-width: 220px;
    }
}

.m-areas>div.m-area>div.m-panel.m-zoompanel.opened>button.m-panel-btn.g-cartografia-flecha-izquierda {
    color: white !important;
}

.m-areas>div.m-area>div.m-panel.m-zoompanel.collapsed {
    height: 40px;
}

#zoomToBox.active {
  background-color: #71A7D3;
  color: white;
}

/* Título */

div#m-zoompanel-titulo {
    background-color: #71a7d3;
    color: white;
    display: block;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    text-align: center;
    width: 100%;
}

.m-zoompanel .activeZoompanelDiv {
    border: 5px solid #92c1e7 !important;
}

/* Bottom positions fix when images row turns into columns */

.m-bottom .m-control.m-container.m-zoompanel>#m-zoompanel-previews {
    display: flex;
}

.m-bottom.m-left>.m-panel.m-zoompanel.opened>.m-panel-controls {
    display: flex;
}

.m-bottom.m-left>.m-zoompanel.opened>.m-panel-btn {
    position: absolute;
    right: -40px;
}

.m-bottom.m-right>.m-zoompanel.opened>.m-panel-btn {
    position: absolute;
    left: -40px;
}

/* .m-bottom.m-right .m-control.m-container.m-zoompanel {
    padding-right: 200px;
} */

/* .m-bottom.m-left .m-control.m-container.m-zoompanel {
    padding-left: 200px;
} */


/* Firefox fix */

@-moz-document url-prefix() {
    .m-bottom.m-right .m-zoompanel.opened {
        display: flex;
    }

    .m-control.m-container.m-zoompanel {
        min-width: 216px;
    }

    /* .m-bottom.m-left .m-control.m-container.m-zoompanel {
        padding-left: 40px;
    } */

    .m-bottom.m-right .m-control.m-container.m-zoompanel {
        padding-right: 0px;
    }
}

/* Mobile fix */

@media only screen and (max-width: 1000px) {

    .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened {
        background-color: rgba(255, 255, 255, 0.5) !important;
        position: absolute;
        height: 100vh;
        margin: 0px;
        padding: calc((100vw - 210px) / 2);
        padding-top: 10px;
        width: 100vw;
        z-index: 99999;
    }

    .m-panel.m-plugin-zoompanel.opened>button {
        left: 0;
        top: 0;
    }


    .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-titulo {
        font-size: 13px;
        padding-left: 0;
        padding-right: 0;
        width: 210px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-previews {
        height: calc(100vh - 50px);
        overflow: scroll;
        width: 210px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-previews>div {
        height: 210px;
        width: 210px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-previews>div>img {
        height: 200px;
        width: 200px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-previews>div>span {
        font-size: 1.3em;
        height: 40px;
        line-height: 40px;
        top: 160px;
        width: 200px;
    }

    /* Firefox fix */
    @-moz-document url-prefix() {
        .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened {
            padding: 10px 50px 0;
        }

        .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-previews {
            padding-bottom: 50px;
        }
    }

    /* Chrome fix */
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened>div.m-panel-controls>div.m-control.m-container.m-zoompanel>div#m-zoompanel-previews {
            height: calc(100vh - 100px);
        }
    }

}

@media only screen and (max-width: 433px) {

    /* Edge fix */
    @supports (-ms-ime-align:auto) {
        .m-areas>div.m-area>div.m-panel.m-plugin-zoompanel.opened {
            padding: 10px 50px 0;
        }
    }
}


.m-areas>div.m-area>div.m-panel.m-zoompanel>button.m-panel-btn {
    background-color: #71A7D3 !important;
    color: white !important;
    /* display: block !important;*/
}

.m-areas>div.m-area>div.m-panel.m-zoompanel.opened.no-collapsible {
    border-radius: 0px;
}

.m-areas>div.m-area>div.m-panel.m-zoompanel .m-panel-controls button,
.m-areas>div.m-area>div.m-panel.m-zoompanel.no-collapsible.opened>.m-panel-controls>.m-zoompanel button {
    padding: 7px 8px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    -webkit-transition: opacity .25s ease 0s;
    transition: opacity .25s ease 0s;
    color: #7A7A73;
    margin: 0;
    outline: none !important;
}


@font-face {
  font-family: 'zoompanel';
  src: url('../fonts/zoompanel.eot?35589107');
  src: url('../fonts/zoompanel.eot?35589107#iefix') format('embedded-opentype'),
       url('../fonts/zoompanel.woff2?35589107') format('woff2'),
       url('../fonts/zoompanel.woff?35589107') format('woff'),
       url('../fonts/zoompanel.ttf?35589107') format('truetype'),
       url('../fonts/zoompanel.svg?35589107#zoompanel') format('svg');
  font-weight: normal;
  font-style: normal;
}

.m-areas>div.m-area>div.m-panel.m-zoompanel>div.m-panel-controls>div.m-control>button,
div.m-panel.m-zoompanel [class^="icon-"]:before, div.m-panel.m-zoompanel [class*=" icon-"]:before {
 font-family: "zoompanel" !important;
 font-style: normal;
 font-weight: normal;
 speak: never;
 display: inline-block;
 text-decoration: inherit;
 text-align: center;
 font-variant: normal;
 text-transform: none;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

div.m-panel.m-zoompanel .icon-zoom-forward:before { content: '\e800'; }
div.m-panel.m-zoompanel .icon-zoom-back:before { content: '\e801'; }
div.m-panel.m-zoompanel .icon-zoom-in:before { content: '\e802'; }
div.m-panel.m-zoompanel .icon-zoom-out:before { content: '\e803'; }
div.m-panel.m-zoompanel .icon-zoom-box:before { content: '\e804'; }
div.m-panel.m-zoompanel .icon-zoompanel:before { content: '\e802'; padding-bottom: 2px;}
div.m-panel.m-zoompanel .icon-zoom-extent:before { content: '\e921'; transform: rotate(-45deg); }
